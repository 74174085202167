<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none" v-if="$can('manage', 'AdminAccess')">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <nav-admin-button v-if="$can('manage', 'AdminAccess')"/>
    <!-- <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <bookmarks />
    </div> -->

    <!-- Right Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- <locale /> -->

      <dark-Toggler class="d-none d-lg-block" />
      <!-- <search-bar /> -->
      <!-- <cart-dropdown /> -->
      <!-- <notification-dropdown /> -->
      <user-dropdown />

    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BButton
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import Bookmarks from './components/Bookmarks.vue'
// import Locale from './components/Locale.vue'
import DarkToggler from './components/DarkToggler.vue'
// import SearchBar from './components/SearchBar.vue'
// import CartDropdown from './components/CartDropdown.vue'
// import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
import AppNavbarHorizontalLayoutBrand from '@core/layouts/components/app-navbar/AppNavbarHorizontalLayoutBrand.vue'
import NavAdminButton from '@/views/prs/components/nav-admin-button/NavAdminButton.vue'

export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    BButton,
    // Bookmarks,
    // Locale,
    DarkToggler,
    // SearchBar,
    // CartDropdown,
    // NotificationDropdown,
    UserDropdown,
    AppNavbarHorizontalLayoutBrand,
    NavAdminButton
  },
  directives: {
    Ripple,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => { },
    },
  },
  methods: {
    success() {
      this.$swal({
        title: `${this.surveyType} Nomination Sent!`,
        text: `${this.requestFrom} was successfully nominated.`,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  }

}
</script>
