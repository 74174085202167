<template>
  <b-button class="mr-2 px-3 py-1" v-ripple.400="'rgba(255, 255, 255, 0.15)'" :variant="buttonState.variant"
    :to="{ name: buttonState.route }" @click="toggleButton" v-b-hover="changeVariant">
    <feather-icon :icon="buttonState.icon" class="mr-50" />
    <span class="align-center"><b>{{ buttonState.text }}</b></span>
  </b-button>
</template>

<script>
import { BButton, VBHover } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import useAppConfig from '@core/app-config/useAppConfig';

export default {
  components: {
    BButton,
  },
  directives: {
    'b-hover': VBHover,
    Ripple,
  },
  data() {
    return {
      isAdminView: false,
      buttonState: {
        text: 'My Surveys',
        icon: 'FileTextIcon',
        route: 'prs-main',
        variant: 'relief-primary',
      },
    };
  },
  setup() {
    const { isNavMenuHidden } = useAppConfig();
    return { isNavMenuHidden };
  },
  methods: {
    toggleButton() {
      this.isAdminView = !this.isAdminView;
      this.checkRoute();
      this.updateButtonState();
    },
    changeVariant(isHovering) {
      this.buttonState.variant = isHovering ? 'outline-primary' : 'relief-primary';
    },
    updateButtonState() {
      if (this.isAdminView) {
        this.buttonState.text = 'My Surveys';
        this.buttonState.icon = 'FileTextIcon';
        this.buttonState.route = 'prs-main';
        this.isNavMenuHidden = false;
      } else {
        this.buttonState.text = 'Admin';
        this.buttonState.icon = 'PocketIcon';
        this.buttonState.route = 'admin-dashboard';
        this.isNavMenuHidden = true;
      }
    },
    checkRoute() {
      if (this.$route.meta.resource === 'AdminAccess') {
        this.isAdminView = true;
      } else {
        this.isAdminView = false;
      }
      this.updateButtonState();
    }
  },
  watch: {
    $route() {
      this.checkRoute();
    }
  },
  mounted() {
    this.checkRoute();
  }
};
</script>
